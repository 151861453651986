import { useState, createContext, useCallback, useEffect, useContext } from 'react'
import localForage from 'localforage'
import Loading from 'components/loading'

export const StoreContext = createContext()

export const StoreProvider = ({ children }) => {

    const [loading, setLoading] = useState(true)
    const [token, rawSetToken] = useState(null)
    const [event, rawSetEvent] = useState(null)

    useEffect(() => {
        const persistStore = async () => {
            try {
                const token = await localForage.getItem('TOKEN')
                if (token) rawSetToken(token)
                const event = await localForage.getItem('EVENT')
                if (event) {
                    const json = JSON.parse(event)
                    rawSetEvent(json)
                }
            } catch (error) {
                console.error(error)
            }
            setLoading(false)
        }
        persistStore()
    }, [])

    const setToken = useCallback((newToken) => {
        rawSetToken(newToken)
        localForage.setItem('TOKEN', newToken)
    }, [])

    const setEvent = useCallback((newEvent) => {
        console.log(newEvent)
        rawSetEvent(newEvent)
        localForage.setItem('EVENT', JSON.stringify(newEvent))
    }, [])

    return loading ? <Loading /> : <StoreContext.Provider value={{
        token,
        setToken,
        event,
        setEvent
    }}>{children}</StoreContext.Provider>

}

export const useStore = () => useContext(StoreContext)

export default useStore