import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Alert, Card, Spinner } from 'react-bootstrap'
import { Formik } from 'formik'
import Field from 'components/formik/field'
import PoweredBy from 'components/brand/powered-by'
import * as yup from 'yup'
import api from 'lib/api'
import style from './style.module.css'
import useStore from 'hooks/use-store'

const AuthWall = ({ children }) => {

  const { token } = useStore()
  
  return !token ? <KeyPad /> : children

}

export default AuthWall

const KeyPad = () => {

  const { t } = useTranslation()
  const { setToken, setEvent } = useStore()
  const [error, setError] = useState()

  const handleSubmit = (values) => {
    return api.post('/auth/login', values)
    .then(result => {
      if (result.token) {
        setToken(result.token)
        setEvent({
          id: result.eventId
        })
      }
    })
    .catch(setError)
  }

  const initialValues = { code: '', password: '' }
  
  const validationSchema = yup.object().shape({
    code: yup.string().required().label('Event code'),
    password: yup.string().required().label('Password')
  })

  return (
    <div className={style.gateContainer}>
      <div />
      <Card body className={style.gateCard}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange={false} validateOnBlur={false}>
          {({ handleSubmit, isSubmitting }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Field type='text' name='code' label='Event code' />
              <Field type='password' name='password' autoComplete='off' label='Password' />
              {error && <Alert variant='warning'>{error.message}</Alert>}
              <div className='text-center mt-3'>
                <Button type='submit' disabled={isSubmitting}>{isSubmitting ? <Spinner color='white' /> : t('Log in')}</Button>
              </div>
            </form>
          )}
        </Formik>
      </Card>
      <PoweredBy />
    </div>
  )
  
}