import { Suspense } from 'react'
import { Switch, Route } from 'react-router'
import { BrowserRouter as Router } from 'react-router-dom'
import Loading from 'components/loading'
import './style/index.scss'

import AuthWall from 'components/auth-wall'
import EventScreen from 'screens/event'
import { StoreProvider } from 'hooks/use-store'

const App = () => {

  return (
    <Suspense fallback={<Loading />}>
      <StoreProvider>
          <Router>
            <AuthWall>
              <Switch>
                <Route exact path='/'><EventScreen /></Route>
              </Switch>
            </AuthWall>
          </Router>
      </StoreProvider>
    </Suspense>
  )
  
}

export default App