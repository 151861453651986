import { useState, useEffect } from 'react'

const AreaImg = ({ src, area, ...props }) => {

    const [img, setImg] = useState()
    const [maxWidth, setMaxWidth] = useState()
    
    if (area.indexOf('rem') === -1) throw new Error('Area must be in rem')

    useEffect(() => {
        if (img && img.src !== src) {
            setImg()
            setMaxWidth()
        }
        const newImg = new Image()
        newImg.onload = () => {
            setImg(newImg)
            const rem = 16
            const remArea = Number(area.split('rem')[0])
            const remWidth = newImg.width / rem
            const remHeight = newImg.height / rem
            setMaxWidth(remWidth * Math.sqrt(remArea / (remWidth * remHeight)))
        }
        newImg.src = src
    }, [src])

    return (<img src={src} style={{ width: maxWidth + 'rem', maxWidth: '100%' }} alt='' {...props} />)

}

export default AreaImg