import { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import { imgBaseUrl } from 'lib/cloudinary'
import AreaImg from 'components/area-img'
import useStore from 'hooks/use-store'
import useAPI from 'hooks/use-api'

import LoadingScreen from './loading'
import ErrorScreen from './error'
import Loading from 'components/loading'
import { ListGroup, ListGroupItem } from 'react-bootstrap'
// import SplashScreen from './splash'

const EventScreen = () => {

    const api = useAPI()
    const { event, setEvent } = useStore()
    const [error, setError] = useState()
    const eventId = event?.id

    useEffect(() => {
        if (!eventId) return
        api.get('/event/' + eventId)
        .then(async event => {
            setEvent(event)
        })
        .catch(setError)
    }, [eventId])

    return error ? (
        <ErrorScreen error={error} />
    ) : event ? (
        <>
            {/* <SplashScreen event={event} setEvent={setEvent} />
            <ActiveScreen event={event} setEvent={setEvent} /> */}
            <BasicScreen event={event} setEvent={setEvent} />
        </>
    ) : <LoadingScreen />
    
}

export default EventScreen

const BasicScreen = ({ event, setEvent }) => {

    const interval = useRef()
    const api = useAPI()

    const poll = useCallback(async () => {
        if (!event?.id) return
        try {
            const actions = await api.get('/event/' + event.id + '/action')
            const entities = await api.get('/event/' + event.id + '/entity')
            setEvent(Object.assign({}, event, {
                actions,
                entities
            }))
        } catch (error) {
            console.error(error)
        }
    }, [event, setEvent, api])

    useEffect(() => {
        interval.current = setInterval(poll, 2 * 1000)
        return () => clearInterval(interval.current)
    }, [poll])

    const { feature, entities, actions, categories } = useMemo(() => {
        const feature = event?.features?.find(f => f.type === 'startup')
        const entities = feature && event?.entities?.filter(e => e.featureId === feature.id).sort((a, b) => a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0)
        const categories = feature && event?.categories?.filter(e => e.featureId === feature.id).sort((a, b) => a.sort < b.sort ? -1 : a.sort > b.sort ? 1 : 0)
        const actions = feature && event?.actions?.filter(a => a.featureId === feature.id)
        return { feature, entities, actions, categories }
    }, [event])

    // let categorized = 0

    return (
        <div style={{ maxWidth: '40rem', margin: '0 auto' }} className='mt-4 mb-5'>
            <div className='mb-5 text-center'><AreaImg src={imgBaseUrl + '/t_scale_300/' + event.logoUrl} alt={event.name} area={'40rem'} /></div>
            {entities?.length > 0 ? (
                categories?.length > 0 ? categories.map((category) => {
                    const filtered = entities?.filter(e => e.categoryId === category.id)
                    // categorized += filtered?.length || 0
                    return (
                        <div key={category.id}>
                            <div className='text-center mt-5 mb-3 h5 bg-secondary text-white p-2 rounded'>{category.name}</div>
                            {filtered.map((entity) => (
                                <div key={entity.id} className='mb-3'>
                                    <EntityItem event={event} entity={entity} actions={actions} categories={categories} />
                                </div>
                            ))}
                        </div>
                    )
                }) : entities.map((entity) => (
                    <div key={entity.id} className='mb-3'>
                        <EntityItem event={event} entity={entity} actions={actions} categories={categories} />
                    </div>
                ))
            ) : <Loading />}
        </div>
    )
    
    
}

const EntityItem = ({ event, entity, actions, categories }) => {

    const results = useMemo(() => {
        if (!actions || !entity) return
        const uniqueVotes = []
        const comments = []
        let resultCount = 0
        let ratingScore = 0
        actions.filter(action => {
            if (action.type !== 'vote' || action.entityId !== entity?.id || !action.data) return false
            if (uniqueVotes.indexOf(action.guestId) > -1) return false
            uniqueVotes.push(action.guestId)
            if (action.data.comments) comments.push(action.data.comments)
            return true
        }).forEach(action => {
            resultCount++
            ratingScore += Number(action.data.rating)
        })
        return {
            rating: ratingScore ? Math.round((ratingScore / resultCount) * 10) / 10 : 0,
            count: uniqueVotes.length,
            comments
        }
    }, [actions, entity])

    // const category = useMemo(() => {
    //     return entity?.categoryId && categories?.find(c => c.id === entity.categoryId)
    // }, [entity, categories])

    const startup = entity
    
    return (
        <div className='card card-body' style={{ display: 'flex', alignItems: 'stretch', justifyContent: 'center', outline: entity?.status === 'active' ? 'inset 0.5rem ' + event.primaryColor : '' }}>
            <div>
                {/* {!!category && <div className='fw-bold mb-3 small'>{category.name}</div>} */}
                <div className='mb-3 fw-bold'>{entity.name}</div>
                {!!startup.imageUrl && <div className='mb-3 mt-3'><AreaImg src={imgBaseUrl + '/t_scale_300/' + startup.imageUrl} alt={startup.name} area={'40rem'} /></div>}
                {startup.data && !!startup.badge && (
                    <div className='mt-3 mb-3'>
                        <div style={{ background: event.primaryColor, color: '#fff', textAlign: 'center', fontSize: '1rem', padding: '0.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>Highest Rated</div>
                    </div>
                )}
                {results && (<>
                    <ListGroup horizontal className='mb-0'>
                        <ListGroupItem>Rating: {results.rating}</ListGroupItem>
                        <ListGroupItem>Count: {results.count}</ListGroupItem>
                    </ListGroup>
                    {/* {results.comments.length > 0 && <div className='mt-3'>Comments/Questions:</div>} */}
                    {results.comments.length > 0 && <div className='list-group mt-3 mb-0'>
                        {results.comments.map((comment, index) => (
                            <div key={index} className='list-group-item'>{comment}</div>
                        ))}
                    </div>}
                </>)}
            </div>
        </div>
    )

}

// const ActiveScreen = ({ event, setEvent }) => {

//     const interval = useRef()
//     const api = useAPI()

//     const poll = useCallback(async () => {
//         if (!event?.id) return
//         try {
//             const actions = await api.get('/event/' + event.id + '/action')
//             const entities = await api.get('/event/' + event.id + '/entity')
//             setEvent(Object.assign({}, event, {
//                 actions,
//                 entities
//             }))
//         } catch (error) {
//             console.error(error)
//         }
//     }, [event, setEvent, api])

//     useEffect(() => {
//         interval.current = setInterval(poll, 2 * 1000)
//         return () => clearInterval(interval.current)
//     }, [poll])

//     const { feature, category, entity, actions } = useMemo(() => {
//         const entity = event?.entities?.find(e => e.status === 'active')
//         const feature = entity && event?.features?.find(f => f.id = entity.featureId)
//         const category = entity && event?.categories?.find(c => c.id = entity?.categoryId)
//         const actions = feature && entity && event?.actions?.filter(a => a.featureId === feature.id && a.entityId === entity.id)
//         return { feature, category, entity, actions }
//     }, [event])

//     const results = useMemo(() => {
//         if (!actions || !entity) return
//         const uniqueVotes = []
//         const comments = []
//         let resultCount = 0
//         let ratingScore = 0
//         actions.filter(action => {
//             if (action.featureId !== entity?.featureId || action.type !== 'vote' || action.entityId !== entity?.id || !action.data) return false
//             if (uniqueVotes.indexOf(action.guestId) > -1) return false
//             uniqueVotes.push(action.guestId)
//             if (action.data.comments) comments.push(action.data.comments)
//             return true
//         }).forEach(action => {
//             resultCount++
//             ratingScore += Number(action.data.rating)
//         })
//         return {
//             rating: ratingScore ? Math.round((ratingScore / resultCount) * 10) / 10 : 0,
//             count: uniqueVotes.length,
//             comments
//         }
//     }, [actions, entity])

//     const startup = entity

//     return results ? (
//         <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, background: '#fff', display: 'flex', alignItems: 'stretch', justifyContent: 'center' }}>
//             <div className='p-4' style={{ width: '100%', maxWidth: '40rem' }}>
//                 {!!category && <p className='fw-bold mb-3'>{category.name}</p>}
//                 {!!startup.imageUrl && <p><AreaImg src={imgBaseUrl + '/t_scale_300/' + startup.imageUrl} alt={startup.name} area={'60rem'} /></p>}
//                 {startup.data && <div className='mt-3 mb-3'>
//                     {!!startup.badge && (
//                         <div style={{ background: event.primaryColor, color: '#fff', textAlign: 'center', fontSize: '1rem', padding: '0.25rem', fontWeight: 'bold', marginBottom: '0.5rem' }}>Highest Rated</div>
//                     )}
//                     <div className='h3'>Rating: {results.rating}</div>
//                     <div className='h3'>Count: {results.count}</div>
//                 </div>}
//                 <div className='fw-bold'>Comments/Questions:</div>
//                 <div className='list-group'>
//                     {results.comments.map((comment, index) => (
//                         <div key={index} className='list-group-item'>{comment}</div>
//                     ))}
//                 </div>
//             </div>
//         </div>
//     ) : null
    
// }