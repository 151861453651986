import Loading from 'components/loading'

const LoadingScreen = () => {
    
    return (
        <Loading />
    )

}

export default LoadingScreen