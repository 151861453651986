import { Spinner } from 'react-bootstrap'

const Loading = () => {

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw' }}>
            <Spinner style={{ color: '#ccc' }} animation='border' />
        </div>
    )
    
}

export default Loading