import React from 'react'
import { useTranslation } from 'react-i18next'
import style from './style.module.css'

const PoweredBy = () => {

    const { t } = useTranslation()

    return (
        <div className={style.poweredBy}>
            <a href='https://imaginate.events' target='_blank' rel='noopener noreferrer noindex nofollow'>Powered by Imaginate Events</a>
        </div>
    )

}

export default PoweredBy