import { Form } from 'react-bootstrap'
import { useField } from 'formik'

const FormikField = props => {
    const [field, meta] = useField(props) // , helpers
    return (
        <Form.Group>
            {props.label && <Form.Label>{props.label}</Form.Label>}
            {props.type === 'select' ? (
                <Form.Control as='select' className='form-select' {...field} {...props} isInvalid={!!meta.error}>
                    {props.children}
                </Form.Control>
            ) : props.type === 'textarea' ? (
                <Form.Control as='textarea' {...field} {...props} isInvalid={!!meta.error} />
            ) : props.type === 'file' ? (
                <Form.Control className='form-control' {...field} {...props} isInvalid={!!meta.error} />
            ) : (
                <Form.Control {...field} {...props} isInvalid={!!meta.error} />
            )}
            {meta.error && (
              <Form.Control.Feedback type='invalid'>
                {meta.error}
              </Form.Control.Feedback>
            )}
        </Form.Group>
    )
}

export default FormikField