import { Alert } from 'react-bootstrap'

const ErrorScreen = ({ error, color = 'danger' }) => {
    
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw', background: '#f2f2f2' }}>
            <Alert color={color}>{error?.message}</Alert>
        </div>
    )

}

export default ErrorScreen